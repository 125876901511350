import { render, staticRenderFns } from "./BaseEditor.vue?vue&type=template&id=6ba44491&scoped=true&"
import script from "./BaseEditor.vue?vue&type=script&lang=js&"
export * from "./BaseEditor.vue?vue&type=script&lang=js&"
import style0 from "./BaseEditor.vue?vue&type=style&index=0&id=6ba44491&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba44491",
  null
  
)

export default component.exports